<template>
  <b-overlay :show="_show" rounded="lg">
    <b-card>
      <b-form @submit.prevent>
        <b-row>
          <b-col md="11">
            <b-row>
              <b-col md="2" class="m-0 p-0">
                <v-select
                    v-model="filter.brand_id"
                    :options="_brands"
                    label="name"
                    :reduce="item => item.id"
                    input-id="brand"
                    multiple
                    placeholder="Markalar"
                />
              </b-col>
              <b-col md="2" class="m-0 p-0">
                <b-form-input type="text" v-model="filter.name" placeholder="Ürün Adı"/>
              </b-col>
              <b-col md="2" class="m-0 p-0">
                <b-form-input type="text" v-model="filter.barcode" placeholder="Ürün Barkod"/>
              </b-col>
              <b-col md="2" class="m-0 p-0">
                <v-select
                    v-model="filter.isBundle"
                    :options="[
                        { label: 'Tümü', value: null },
                        { label: 'Takım', value: 1 },
                        { label: 'Ürün', value: 0 }
                    ]"
                    placeholder="Tür Seç"
                    :reduce="option => option.value"
                />
              </b-col>

              <b-col md="1" class="m-0 p-0">
                <v-select
                    v-model="filter.limit"
                    :options="[10, 20, 50, 100, 250, 500]"
                    placeholer="Limit"
                />
              </b-col>
              <b-col md="1" class="m-0 p-0">
                <v-select
                    v-model="filter.sorting"
                    label="key"
                    :options="[{key: 'A-Z', val: 'ASC'}, {key: 'Z-A', val: 'DESC'}]"
                    :reduce="item => item.val"
                    placeholder="Sıralama"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col md="1">
            <b-button-group size="sm">
              <b-button variant="primary" @click="getAll()">Getir</b-button>
              <b-button variant="danger" @click="filterClear" type="reset">Temizle</b-button>
            </b-button-group>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </b-overlay>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import vSelect from "vue-select";


export default {
  name: "SearchProductV2",
  components: {vSelect},
  computed: {
    ...mapState('productV2', ['filter']),
    ...mapGetters('productV2', ['_show']),
    ...mapGetters('productBrand', ['_brands']),
  },
  methods: {
    getAll() {
      this.$store.dispatch('productV2/getProducts')
    },
    filterClear() {
      this.filter = {
        name: '',
        limit: 10,
        brand_id: []
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
