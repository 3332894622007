<template>
  <section>
    <SearchProductV2/>
    <SelectedProducts/>
    <ProductsTable/>
    <Edit/>
  </section>
</template>

<script>
import SearchProductV2 from "@/views/v2/products/SearchProducts.vue";
import ProductsTable from "@/views/v2/products/ProductsTable.vue"
import SelectedProducts from "@/views/v2/products/SelectedProducts.vue"
import Edit from "@/views/product/Edit";

export default {
  name: "ProductsHomeV2",
  components: {SearchProductV2, ProductsTable, SelectedProducts, Edit},
  mounted() {
    this.$store.dispatch('productBrand/getAll');
  },
}

</script>