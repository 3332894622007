<template>
  <b-card>
    <b-row>
      <b-col md="10">
        Toplam Seçilen Ürün {{ _selectedProducts.length }} adettir.
      </b-col>
      <b-col md="2">
        <b-button-group size="sm">
          <b-button :disabled="_selectedProducts.length<2" variant="primary" @click="addNewProduct($event,1)">
            <feather-icon icon="PlusSquareIcon"></feather-icon>
            Grup Oluştur
          </b-button>
        </b-button-group>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: 'SelectedProducts',
  computed: {
    ...mapGetters('productV2', ['_selectedProducts'])
  },
  methods: {
    addNewProduct(event, isBundle) {
      this.$store.commit('product/setEditProduct', {isBundle, sub_products: this._selectedProducts});
      this.$root.$emit('bv::show::modal', 'edit-product-modal', event.target)
    },
  }
}
</script>