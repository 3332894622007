<template>
  <b-table :items="_products" :fields="fields">
    <template #cell(select)="row">
      <b-form-checkbox
          v-model="$store.state.productV2.selectedProducts"
          :value="row.item"
          :disabled="!!row.item.isBundle"
          class="custom-control-secondary"
      />
    </template>

    <template #cell(edit)="row">
      <b-button variant="primary" size="sm" @click="editProduct($event, row.item)">
        <feather-icon icon="EditIcon"/>
        Düzenle
      </b-button>
    </template>
  </b-table>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ProductsTable",
  data: () => ({
    fields: [
      {key: 'select', label: 'Seç'},
      {key: 'id', label: 'Sıra No'},
      {key: 'name', label: 'Ürün Adı'},
      {key: 'barcode', label: 'Ürün Barkod'},
      {key: 'brand.name', label: 'Marka'},
      {
        key: 'isBundle', label: 'Türü',
        formatter: (value, key, item) => item.isBundle ? 'Takım' : 'Ürün',
        tdClass: (value, key, item) => item.isBundle ? 'text-primary font-weight-bold' : 'text-success'
      },
      {key: 'edit', label: 'Düzenle'}
    ]
  }),
  computed: {
    ...mapGetters('productV2', ['_products'])
  },
  methods: {
    editProduct(event, item) {
      this.$store.commit('product/setEditProduct', item);
      this.$root.$emit('bv::show::modal', 'edit-product-modal', event.target)
    },
  }
}

</script>